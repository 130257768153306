import React, { ReactElement, useState, useRef, useCallback } from 'react'

import { graphql } from 'gatsby'
import Slider from 'react-slick'
import Iframe from 'react-iframe'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import loadable from '@loadable/component'

import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaRegEnvelope,
} from 'react-icons/fa'
import next from '../../assets/images/next.png'
import back from '../../assets/images/back.png'

import fbIcon from '../../assets/images/news-images/fb.png'
import twitterIcon from '../../assets/svg/twitter.svg'
import messageIcon from '../../assets/images/news-images/message.png'
import shareIcon from '../../assets/images/news-images/share.png'
import arrowGreen from '../../assets/images/icons/right-arrow-green.png'
import mobileShare from '../../assets/images/mobile-images/icons/share.png'
import quotationMark from '../../assets/images/icons/quote.png'
import Seo from '../../components/Seo/Seo'

import { ContentTypes } from '../SustainabilityPillar/SustainabilityPillar'

import placeholder from '../../assets/images/location-images/location-placeholder.png'
import Layout from '../../components/Layout/Layout'
import './Articles.scss'

const Modal = loadable(() => import('../../components/Modal/Modal'))

type PillarPropTypes = {
  data: {
    articleData: ContentTypes
  }
}

const categories: any = {
  blogs: 'blog',
  events: 'news-and-events',
  vlogs: 'vlogs',
  news: 'news-and-events',
}

const Articles = ({ data: { articleData } }: PillarPropTypes): ReactElement => {
  const [shareUrl] = useState(
    `${process.env.GATSBY_SITE_URL || 'z'}/${
      categories[articleData.category]
    }/${articleData.tags ? articleData.tags[0] : null}/`,
  )
  const [copied, setCopied] = useState(false)
  const [contents] = useState(articleData.contents)
  const [title] = useState(articleData.title)
  const [category] = useState(articleData.category)
  const [content] = useState(articleData.shortDescription)
  const [image] = useState(articleData?.image?.url)
  const [video] = useState(articleData.contents[0]?.video)
  const [isLocationDetailsShowing, setIsLocationDetailsShowing] = useState<
    boolean
  >(false)
  const [isVideoFullScreen, setIsVideoFullScreen] = useState<boolean>(false)

  const [currentSlide, setCurrentSlide] = useState(0)

  const [displayFeaturedImages] = useState<string[]>(
    articleData.contents.map((imageContent) => imageContent?.image) || [],
  )

  const sliderRef = useRef<Slider>(null)

  const handleBeforeChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex)
    },
    [],
  )

  const nextImage = useCallback(() => {
    if (sliderRef?.current?.slickNext) {
      sliderRef.current.slickNext()
    }
  }, [sliderRef])

  const prevImage = useCallback(() => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef.current.slickPrev()
    }
  }, [sliderRef])

  const goToSlide = useCallback(
    (index: number) => {
      if (sliderRef?.current?.slickGoTo) {
        sliderRef.current.slickGoTo(index)
      }
    },
    [sliderRef],
  )

  const copyUrl = useCallback(
    (e: React.MouseEvent): void => {
      e.preventDefault()

      navigator.clipboard.writeText(shareUrl)
      setCopied(true)

      setTimeout(() => {
        setCopied(false)
      }, 1500)
    },
    [shareUrl],
  )

  const openShare = useCallback((): void => {
    const newVariable: any = window.navigator

    if (newVariable && newVariable.share) {
      newVariable.share({
        url: shareUrl,
      })
    }
  }, [shareUrl])

  const brokenImage = (event: any): void => {
    event.target.src = placeholder // eslint-disable-line no-param-reassign
  }

  const tags = useState(articleData.tags?.map((tag) => tag))

  const seoTitle = articleData.seoTitle
    ? articleData.seoTitle
    : articleData.title

  const seoDescription = articleData.seoDescription
    ? articleData.seoDescription
    : articleData.seoDescription

  const [articleCategory] = useState(articleData.category)
  return (
    <Layout mobile mobileArticles articleCategory={articleCategory}>
      <Seo
        title={seoTitle}
        jsonData={{
          keywords: JSON.stringify(tags[0]).substring(
            1,
            JSON.stringify(tags[0]).length - 1,
          ),
        }}
        ogMetaData={{
          title: seoTitle,
          image,
          type: 'article',
          description: seoDescription,
          url: shareUrl,
        }}
        twitterMetaData={{
          title: seoTitle,
          image,
          description: seoDescription,
        }}
      />
      {category !== 'news' && category !== 'blogs' ? (
        <div className="article">
          <div className="article-subjects">
            <div className="article-info">
              <h4 className="title">{title}</h4>
              <h4 className="type">{category}</h4>
              <h4 className="sub-title">{contents[0]?.title}</h4>
              <h4
                className="description"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html:
                    contents[0]?.description?.substring(0, 570) +
                    (contents[0]?.description?.length > 570 ? '...' : ''),
                }}
              />
              {contents[0]?.description?.length >= 570 ? (
                <button
                  type="button"
                  onClick={(): void => setIsLocationDetailsShowing(true)}
                  className="read-more"
                >
                  Read More
                </button>
              ) : null}
              {category !== 'vlogs' ? null : (
                <div>
                  <div className="share">
                    <h1 className="title">SHARE US</h1>
                    <div className="icons">
                      <FacebookShareButton url={shareUrl}>
                        <img src={fbIcon} alt="&times;" aria-hidden />
                      </FacebookShareButton>
                      <TwitterShareButton url={shareUrl}>
                        <img src={twitterIcon} alt="&times;" aria-hidden />
                      </TwitterShareButton>
                      <a href={`mailto:?body=${shareUrl}`}>
                        <img src={messageIcon} alt="&times;" aria-hidden />
                      </a>
                      <div className="clipboard-action">
                        <button type="button" onClick={copyUrl}>
                          <img src={shareIcon} alt="&times;" aria-hidden />
                        </button>
                        {copied && <p>Copied!</p>}
                      </div>
                    </div>
                  </div>
                  <div className="comments">
                    <button
                      type="button"
                      onClick={(): void => setIsVideoFullScreen(true)}
                      className="label"
                    >
                      <span>Leave a comment</span>
                      <img src={arrowGreen} alt="arrowIcon" />
                    </button>
                  </div>
                </div>
              )}
              {category !== 'vlogs' && (
                <div>
                  <h4 style={{ fontWeight: 'bold', color: '#1a242a' }}>
                    SHARE THIS ARTICLE
                  </h4>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '30px',
                      width: '220px',
                    }}
                  >
                    <FacebookShareButton url={shareUrl}>
                      <FaFacebookF
                        style={{ color: '#155121', fontSize: '25px' }}
                      />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl}>
                      <FaTwitter
                        style={{ color: '#155121', fontSize: '25px' }}
                      />
                    </TwitterShareButton>
                    <LinkedinShareButton url={shareUrl}>
                      <FaLinkedinIn
                        style={{ color: '#155121', fontSize: '25px' }}
                      />
                    </LinkedinShareButton>
                    <EmailShareButton url={shareUrl}>
                      <FaRegEnvelope
                        style={{ color: '#155121', fontSize: '25px' }}
                      />
                    </EmailShareButton>
                  </div>
                </div>
              )}
            </div>
            {category !== 'vlogs' ? null : (
              <div className="mobile-share">
                <button
                  type="button"
                  onClick={(): void => setIsVideoFullScreen(true)}
                  className="mobile-share-comment"
                >
                  <span>Leave a comment</span>
                </button>
                <button
                  type="button"
                  onClick={(): void => openShare()}
                  className="mobile-share-button"
                >
                  <img src={mobileShare} alt="&times;" aria-hidden />
                </button>
              </div>
            )}
            {category !== 'vlogs' ? (
              <div className="article-image-slider">
                <Slider
                  swipeToSlide={false}
                  arrows={false}
                  dots={false}
                  beforeChange={handleBeforeChange}
                  fade
                  easing="ease"
                  className="banner-slider"
                  ref={sliderRef}
                >
                  {displayFeaturedImages
                    .filter((item) => item)
                    .map((imageItems) => (
                      <LazyLoadImage
                        key={imageItems}
                        className="slider-image"
                        src={imageItems}
                        placeholder={<img id="placeholder" alt="slider-img" />}
                      />
                    ))}
                </Slider>
                <div className="nav-image-container">
                  <h4>Gallery</h4>
                  <div className="container">
                    {displayFeaturedImages
                      .filter((item) => item)
                      .map((imageItems, index) => (
                        <button
                          type="button"
                          key={imageItems}
                          onClick={(): void => goToSlide(index)}
                        >
                          <LazyLoadImage
                            src={imageItems}
                            placeholder={
                              <img id="placeholder" alt="featured-img" />
                            }
                            className={`slider-nav${
                              currentSlide === index ? ' active' : ''
                            }`}
                          />
                        </button>
                      ))}
                  </div>
                </div>
                <div className="nav-button-container">
                  <button
                    type="button"
                    className="nav"
                    onClick={(): void => prevImage()}
                  >
                    <img src={back} alt="backIcon" />
                  </button>
                  <button
                    type="button"
                    className="nav"
                    onClick={(): void => nextImage()}
                  >
                    <img src={next} alt="nextIcon" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="article-image-slider">
                <Iframe
                  allowFullScreen
                  url={video}
                  width="670px"
                  height="450px"
                  id="myId"
                  position="relative"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="article">
          <div className="article-content">
            <div className="cover-photo">
              <LazyLoadImage
                src={image}
                onError={brokenImage}
                alt="article-cover"
                placeholder={<img id="placeholder" alt="cover-img" />}
              />
            </div>
            <div className="article-content-info">
              <h4
                className="title"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: title }}
              />
              {contents.map((item) => {
                return (
                  <div key={title}>
                    {item?.image !== null ? (
                      <LazyLoadImage
                        src={item?.image}
                        alt=""
                        className="content-image"
                        placeholder={
                          <img id="placeholder" alt={`${item.title}`} />
                        }
                      />
                    ) : null}
                    {item.title !== null ? (
                      <h4
                        className="subtitle"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: item?.title }}
                      />
                    ) : null}
                    {item.description !== null ? (
                      <h4
                        className="description"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                      />
                    ) : null}
                    {item.message !== null ? (
                      <div className="message-box">
                        <img src={quotationMark} alt="quotation-mark" />
                        <p
                          className="message"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: item?.message,
                          }}
                        />
                      </div>
                    ) : null}
                  </div>
                )
              })}
            </div>
            <div className="article-share-btns">
              <h4>SHARE THIS ARTICLE</h4>
              <div>
                <FacebookShareButton url={shareUrl}>
                  <FaFacebookF style={{ color: '#155121', fontSize: '25px' }} />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                  <FaTwitter style={{ color: '#155121', fontSize: '25px' }} />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl}>
                  <FaLinkedinIn
                    style={{ color: '#155121', fontSize: '25px' }}
                  />
                </LinkedinShareButton>
                <EmailShareButton url={shareUrl}>
                  <FaRegEnvelope
                    style={{ color: '#155121', fontSize: '25px' }}
                  />
                </EmailShareButton>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        shareUrl={shareUrl}
        className="left"
        side
        isShowing={isLocationDetailsShowing}
        onClose={(): void => setIsLocationDetailsShowing(false)}
      >
        <div className="details">
          <div className="share-icons" />
          <h1 className="title">{title}</h1>
          <h1 className="type">{category?.toLocaleUpperCase()}</h1>
          <img
            src={image}
            alt="property-banner"
            className="selected-property-location-details-banner"
          />
          {contents.map((item) => {
            return (
              <div key={item.title}>
                <h4 className="sub-title">{item.title}</h4>
                <h4
                  className="description"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: item?.description }}
                />
              </div>
            )
          })}
        </div>
      </Modal>

      <Modal
        className="left"
        noIcons
        side
        isShowing={isVideoFullScreen}
        onClose={(): void => setIsVideoFullScreen(false)}
      >
        <div>
          <iframe
            title="comment-section"
            className="comment-section"
            src={`https://www.facebook.com/plugins/comments.php?href=${shareUrl}`}
            scrolling="auto"
          />
        </div>
      </Modal>
    </Layout>
  )
}

export default Articles

export const pageQuery = graphql`
  query ArticleQuery($id: String!) {
    articleData: content(id: { eq: $id }) {
      ...SustainabilityFields
    }
  }
`
